/* --- Global Styles --- */
html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
}

body {
  display: flex;
  flex-direction: column;
}

/* --- Table Styles --- */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
  vertical-align: top;
}

/* Specific styles for the Event Name column */
.event-name-column {
  width: 100px !important;
  max-width: 100px !important;
  min-width: 50px !important;
}

.event-name-cell {
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* --- Logo Styling --- */
.navbar-brand {
  padding: 10px;
}

.navbar-brand img {
  max-width: 150px;
  height: auto;
  padding: 5px;
  object-fit: contain;
}

/* --- Navbar Styles --- */
.navbar {
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #3A2C86;
}

.navbar .container {
  margin: 0;
  padding: 0 0px;
  width: 100%;
}

.nav-item {
  margin-left: 0px;
}

/* Common font styling for navigation items */
.nav-item-font {
  color: #737070;
  font-weight: 400;
  font-size: 16px;
}

.navbar-purple {
  background-color: #3A2C86;
}

/* --- Sub-Navigation Styles --- */
.subnav-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: visible;
  box-sizing: border-box;
}

.navbar-gray {
  background-color: LightGray;
}

.subnav-navbar {
  background-color: #ffffff;
  border: 2px dashed cyan;
}

.subnav-links {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: left;
  gap: 0px;
}

/* --- Dropdown Menu Styles --- */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: -20;
  z-index: 1050;
  background-color: #d3d3d3 !important;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 0;
}

.dropdown-menu.show {
  display: block;
  padding: 5px 1px;
  line-height: 1.0;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-item {
  padding-left: 5px;
  line-height: 1.5;
}

.dropdown-item.active, 
.dropdown-item:active {
  background-color: inherit !important;
  color: inherit !important;
}

.events-section {
  width: 100%;
  min-width: 300px;
  overflow-x: auto;
  flex: none;
}

.registered-events, .upcoming-events {
  margin-bottom: 0;
  padding: 0;
}

.registered-events table, .upcoming-events table {
  margin-bottom: 0;
}

/* --- PowerBI App Container --- */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.report-style {
  width: 100%;
  height: 100%;
  display: block;
}

.powerbi-report-container {
  height: calc(100vh - 56px);
  width: 100vw;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
}

.btn-purple {
  background-color: #3A2C86;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-purple:hover {
  background-color: #2a216e;
}

/* --- Error and Loading Messages --- */
.error-message,
.loading-message {
  text-align: left;
  margin-top: 20px;
}

.error-message {
  color: red;
}

.loading-message {
  color: #000;
}

/* Events Button Styles */
.button-same-width {
  width: 100px;
  text-align: center;
}

.navbar-nav {
  padding-left: 10px;
}

/* --- Responsive Adjustments --- */
@media (min-width: 800px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

@media (max-width: 800px) {
  .powerbi-report-container {
    height: calc(100vh - 56px);
  }
  .navbar-collapse.show .navbar-nav {
    padding-left: 10px;
  }
  .events-section {
    flex: none;
    width: 100%;
  }
  .registered-events {
    margin-bottom: 20px;
  }
  .navbar .container {
    padding: 0 0px;
  }
  .navbar-brand img {
    max-width: 100px !important;
    height: auto !important;
  }
  .nav-item {
    margin-left: 0px;
  }
  .navbar-toggler {
    display: block;
    border: 0px;
    color: #000000;
  }
  .subnav-container {
    flex-wrap: nowrap;
    padding-left: 10px;
  }
  .subnav-links {
    flex-wrap: nowrap;
  }
  .powerBI-nav {
    flex-wrap: nowrap;
  }
  .navbar-collapse {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
  .navbar-collapse .subnav-link,
  .navbar-collapse .subnav-dropdown,
  .navbar-collapse .powerBI-nav {
    width: 100%;
    flex-wrap: nowrap;
  }
  .dropdown-menu {
    padding: 0px 0px;
    box-shadow: none !important;
    border: none;
  }
}

/* --- Contact Button Styles --- */
.contact-button {
  background-color: #3A2C86;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
}

.contact-button:hover {
  background-color: #2a216e;
}

/* --- Help Button Styles --- */
.help-button {
  background-color: #FFC107;
  color: #000;
  border-radius: 5px;
  padding: 10px 20px;
  position: fixed;
  bottom: 20px;
  left: 80px;
  right: 0px;
  z-index: 1000;
}

.help-button:hover {
  background-color: #e0a806;
}