.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Distribute items evenly */
    align-items: center; /* Center items vertically */
  }
  
  .nav-item {
    margin: 0 10px; /* Add margin between items */
  }
  
  .nav-link {
    padding: 10px 15px; /* Add padding to links */
  }
  
  .dropdown {
    margin: 0 10px; /* Add margin between dropdowns */
  }
  
  .dropdown-item {
    padding: 5px 0px; /* Increase left padding for dropdown items */
    margin: 0px 10px; /* 1st for vertical spacing between text, 2nd for side margin on */
  }
  
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #c1bdbd; /* Set the background highlight color */
    margin-left: 0px; /* Move the highlight color 5 pixels to the left */
    padding-left: 15px; /* Adjust padding to maintain text alignment */
  }
  
  .nav-item-font {
    color: #737070; /* Dropdown text coloring */
    font-weight: 400; /* Example font weight */
    font-size: 16px; /* Example font size */
  }