.grid-container {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-gap: 5px;
    min-height: 100vh;
    padding: 10px;
    overflow: hidden;
  }
  
  .mrc-message {
    background-color: rgb(255, 235, 235);
    border: 1px solid #F66;
    padding: 0.5% 2%;
    margin: 1px 0;
    border-radius: 6px;
  }
  
  .rsvp-events {
    margin-top: 1vh;
    margin-bottom: 1px;
  }
  
  .powerbi-report {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0px;
    height: 100%;
  }
  
  .loading-container {
    color: purple;
    display: flex;
    align-items: left;
    height: 100%;
    width: 100%;
    border: 0px;
    padding: 0px;
    border-radius:0px;
    justify-content: center;
  }